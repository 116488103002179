<template>
    <div>
        <div class="spacing_top table-responsive">
            <div v-for="(curb_files, curb_type) in files" v-bind:key="curb_type">
                <h3>{{ curb_type }}</h3>
                <table class="table">
                    <tbody>
                    <tr>
                        <th>Curb Part #</th>
                        <th>File</th>
                        <th></th>
                        <th>Date</th>
                        <th>By</th>
                    </tr>
                    <tr v-for="file in curb_files" v-bind:key="file.cdi_file_id">
                        <td>
                            {{ file.curb_nomenclature }}
                        </td>
                        <td>
                            <ProtectedLink :type="'download'" :linkType="'link'" :text="file.cdi_file_title" :id="file.cdi_file_id" :queryString="queryString" />
                        </td>
                      <td>
                        <button class="btn btn-default" @click="$refs.deleteFile.open(file)"><span class="glyphicon glyphicon-trash"></span></button>
                      </td>
                        <td>
                            {{ file.cdi_file_datetime }}
                        </td>
                        <td>
                            {{ file.employee }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Confirm ref="deleteFile" :heading="'Disable File?'" :body="'Would you like to disable this file?'" @response="deleteFile" />
    </div>
</template>

<script>
import helpers from "@/store/helpers";
import ProtectedLink from "@/components/utils/ProtectedLink";
import {store} from "@/store/BusinessObject.store";
import Confirm from "@/components/utils/Confirm";

export default {
    name: "Files",
    props: ['files'],
    components: {
        ProtectedLink,
        Confirm},
    data () {
        return {
            state: store.state,
        }
    },
    methods: {
        deleteFile: function(file) {
            if (file) {
                store.apiWithObjectLoad('disable_conforming_file', {params: file.cdi_file_id});
            }
        },
    },
    computed: {
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    }
}
</script>

<style scoped>

</style>
export const conformingStampManagerStore = {
    state: {
        name: 'conforming_stamp',
        title: 'Conforming Stamp',
        titlePlural: 'Conforming Manager',
        nav: {
            base: 'conforming_stamp',
            newView: true,
            hideUpload: true,
            views: [
                { view: 'details', label: 'Details', },
                { view: 'mass_upload', label: 'Mass Upload', alwaysVisible: true},
            ]
        },
        search: {
            endPoint: 'conforming_stamp',
            formFields: {},
            resultFields: {
                conforming_type_id: {
                    header: 'Conforming ID:',
                    link: ''
                },
                conforming_year: {
                    header: 'Code Year:',
                    link: ''
                },
                current: {
                    header: 'Current:'
                }
            },
        },
        object: {
            fields: [
                {
                    name: 'conforming_type',
                    label: 'Conforming Type ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'conforming_year',
                    label: 'Conforming Year',
                    component: 'DetailsStatic'
                }
            ],
        }
    }
}
<template>
    <div>
        <New v-if="isCurrentView('new')" />
        <MassUpload v-else-if="isCurrentView('mass_upload')" />
        <div v-if="id">
            <div v-show="isCurrentView('details')">
                <Header :data="state.object.data" />
                <Files :files="state.object.data.files"/>
            </div>
        </div>
        <div v-if="isCurrentView('search')">
            <h1>Conforming Manager</h1>
            <div class="panel" v-for="(details, type) in state.search.results.results" v-bind:key="details.conforming_type_id">
                <label class='h2'>{{ type }}</label>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th v-for="(field, id) in state.search.resultFields" v-bind:key="id">{{ field.header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="detail in details" v-bind:key="detail.conforming_type_id">
                        <td>
                            <router-link :to="`/conforming_stamp/${detail.conforming_type_id}`">
                            {{ detail.conforming_type_id }}
                            </router-link>
                        </td>
                        <td> {{ detail.conforming_year }}</td>
                        <td>
                          <input type="checkbox" :true-value="1" :false-value="0" :checked="detail.current" @click="isCurrent(detail.conforming_type_id, detail.conforming_location_id)" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store/BusinessObject.store.js";
import { conformingStampManagerStore } from "@/components/tools/ConformingStampManager/ConformingStampManager.store.js";
import New from "@/components/tools/ConformingStampManager/New";
import Header from "@/components/tools/ConformingStampManager/Header"
import MassUpload from "@/components/tools/ConformingStampManager/MassUpload";
import Files from './Files';



export default {
    name: "Conforming_Manager",
    props: ['id'],
    components: {
        MassUpload,
        New,
        Header,
        Files

    },
    data() {
        return {
            state: store.state,
            conformingManagerState: conformingStampManagerStore.state,
            cdiLocationID: 1,
            cache: {},
        }
    },
    created() {
        store.initialize(this.conformingManagerState);
        this.load();
    },
    methods: {
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        load: function() {
            if (this.id){
                store.load(this.id)
            } else {
                store.api('to_json_dashboard','', 'search.results')
            }
        },
        isCurrent: function(conforming_type_id, conforming_location_id){
            let options = {
              'params[conforming_location_id]': conforming_location_id,
              'params[conforming_type_id]': conforming_type_id,
            };
            store.api('assign_current_conforming', options, 'search.results', 'conforming_stamp')
        }

    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        }
    },
    watch: {
        id: function (){
            this.load();
        },
        currentView: function(){
          this.state.nav.hideUpload = this.state.currentView != 'details';
        }
    }
}
</script>

<style scoped>
.material_panel {
    padding: 5px 10px;
    margin-bottom: 15px;
    border: 1px solid #D4D8DD;
    overflow: auto;
    min-height: 110px;
}
</style>

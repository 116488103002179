export default {
	LOCATIONS: [
		'A3',
		'AB1',
		'AB2',
		'AB3',
		'B1',
		'B',
		'B3',
		'BC3',
		'BC2',
		'BC1',
		'C1',
		'A',
		'C',
		'A1',
		'DA1',
		'DA2',
		'DA3',
		'D3',
		'D',
		'D1',
		'CD3',
		'CD2',
		'CD1',
		'C3'
	],
	ROW_1_LOCATIONS: [
		'A3',
		'AB1',
		'AB2',
		'AB3',
		'B1',
		'B',
		'B3',
		'BC3',
		'BC2',
		'BC1',
		'C1',
	],
	ROW_2_LOCATIONS: [
		'A',
		'C',
	],
	ROW_3_LOCATIONS: [
		'A1',
		'DA1',
		'DA2',
		'DA3',
		'D3',
		'D',
		'D1',
		'CD3',
		'CD2',
		'CD1',
		'C3'
	],
	TYPES: [
		[562, 43],
		[563, 59],
		[564, 83],
		[565, 120],
		[566, 155],
		[568, 236],
		[569, 300],
		[570, 395]
	],
	
	CONFIGURATIONS: [
		'BASE',
		'MAX'
	]
}
<template>
    <div>
    <h1> {{ conformingName }} </h1>
        <div class="row panel-body details_container">
        </div>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';

export default {
    name: "Header",
    props: ['data'],
    data() {
        return {
            state: store.state,
        }
    },
    methods: {},
    computed: {
        conformingName: function() {
            return `${this.data.conforming_type_id}: ${this.data.conforming_type} ${this.data.conforming_year}`
        }
    },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <h1>Conforming File Mass Upload</h1>
    <div class="well well-sm">
      <button @click="upload()" class="btn btn-default conforming-button">
        <span class="glyphicon glyphicon-plus"></span>
      </button>
      <div class="conforming-button conforming-header">Add File</div>
      <div v-if="fileState.massUploadFilePresent">{{ fileState.conformingTypeID }} {{ fileState.fileTitle }}</div>
    </div>
    <form @submit.prevent="lookup()">
      <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Model</label>
            <Typeahead ref="lookupModel" cdi_type="pm_model_conforming" v-bind:ID.sync="lookupModelID"/>
          </div>
        </div>
      </div>

      <input type="submit" class="btn btn-primary btn_min_width margin_bottom"  value="Lookup">
    </form>
    <div v-if="curbOptions.length > 0">
      <form @submit.prevent="duplicate()">
        <input type="submit" class="btn btn-primary btn_min_width" value="Upload File to Selected Curbs">
        <button @click="clearModelData()" class="btn btn-default btn_min_width">
          Clear Model #
        </button>
        <h3>Result for: {{ $refs.lookupModel.$data.object }}</h3>
        <em>Hold shift and click one checkbox and then another to select a group of checkboxes</em>
        <div class="row">
          <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-for="(row, index) in curbOptions" v-bind:key="row.curb_id" :value="row.curb_id">
            <label>
              {{ row.nomenclature }}
              <input type="checkbox" v-model="curbsToUpdate" :value="row.curb_id" @click.shift="multiCheck(index)">
            </label>
          </div>
        </div>


      </form>

    </div>
  </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import springConfigurationCache from '@/cache/springConfiguration.cache.js'
import { massFileUploadStore } from "@/store/MassFileUpload.store";
export default {
  name: "MassUpload",
  components: {Typeahead},
  data() {
    return {
      state: store.state,
      fileState: massFileUploadStore.state,
      cache: {
        springConfigurationCache
      },
      startCheckboxIndex: null,
      lookupModelID: '',
      curbOptions: [],
      curbsToUpdate: [],
    }
  },
  methods: {
    duplicate: function () {
      massFileUploadStore.massUpload(this.curbsToUpdate);
    },
    resetData: function() {
      this.startCheckboxIndex = null;
      this.curbsToUpdate = [];
      this.fileState.massUploadFilePresent = false;
    },

    multiCheck: function (index) {
      if (this.startCheckboxIndex === null) {
        this.startCheckboxIndex = index;
        return;
      }
      let start = this.startCheckboxIndex;
      let end = index;
      this.curbOptions.slice(Math.min(start, end), Math.max(start, end) + 1).forEach(row => {
        if (!this.curbsToUpdate.includes(row.curb_id)) {
          this.curbsToUpdate.push(row.curb_id);
        }
      });
      this.startCheckboxIndex = null;
    },
    lookup: function () {
      let options = {
        'params[unit_model_id]': this.lookupModelID,
        'params[configuration_type]': this.configurationType
      };
      store.customApi('curb/lookup_conforming_curbs', options).then(results => this.curbOptions = results);
    },
    upload: function () {
      this.$appStore.state.fileUploadModalOpen = true;
      this.$appStore.setFileMassUploadContext('conforming_mass_upload');
    },
    clearModelData: function () {
      this.lookupModelID  = '';
      this.$refs.lookupModel.$data.object = null;
      this.curbOptions = [];
    },
  }
}
</script>

<style scoped>
.conforming-button{
  display: inline-block;
}
.conforming-header{
  padding: 1px 10px;
}
</style>
<template>
    <div>
        <h3>New Conforming Type</h3>
        <form @submit.prevent="save">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Location</label>
                    <select class="form-control" v-model="conformingLocationID">
                        <option v-for="[id, type] in conformingStampLocations" v-bind:key="id" :value="id">
                            {{ type }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Code Year</label>
                    <input type="text" v-model="conformingYear" class="form-control">
                </div>
            </div>
            <button class="btn btn-default">Save Conforming Type</button>
        </form>
    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store";
import conforming from '@/cache/conforming_stamp.cache.js';

export default {
    name: "New",
    components: {},
    data() {
        return {
            state: store.state,
            conformingLocationID:'',
            conformingStampLocations: [],
            conformingYear: '',
            cache: {
                conforming,
            },
        }
    },
    created() {
        store.clearObjectData();
        this.get_conforming_stamp_locations();
    },
    methods: {
        save: function() {
            let options = {
                'params[conforming_location_id]': this.conformingLocationID,
                'params[conforming_year]': this.conformingYear,
            };

            store.api('new_conforming_type', options, '', 'conforming_stamp')
                .then(result => {
                    if (result.opt == 'save') {
                        this.$router.push({path: `/${this.state.nav.base}/${result.conforming_type_id}`});
                    }
                });
        },
        get_conforming_stamp_locations: function () {
          store.customApi('menu_builder/conforming_stamp_locations').then(
              res => {
                this.conformingStampLocations = res;
              }
          )
        },
    },
}
</script>

<style scoped>
</style>